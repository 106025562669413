var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table', {
    attrs: {
      "sticky-header": "600px",
      "responsive": "xl",
      "show-empty": "",
      "busy": _vm.isLoading,
      "hover": "",
      "isLoading": _vm.isLoading,
      "fields": _vm.fields,
      "items": _vm.discounts
    },
    scopedSlots: _vm._u([{
      key: "head()",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(row.label) + " ")])];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + row.index + 1) + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c('div', [_vm._v(" " + _vm._s(row.item.offers.name ? row.item.offers.name : '') + " ")])];
      }
    }, {
      key: "cell(offer_status)",
      fn: function (row) {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.offerStatus(row.item.offers.offer_status)) + " ")])];
      }
    }, {
      key: "cell(offer_type)",
      fn: function (row) {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.offerType(row.item.offers.offer_type_id)) + " ")])];
      }
    }, {
      key: "cell(price_idr)",
      fn: function (row) {
        return [_c('div', [_vm._v(" " + _vm._s(row.item.offers.price_idr ? row.item.offers.price_idr : "Don't have Price") + " ")])];
      }
    }, {
      key: "cell(is_free)",
      fn: function (row) {
        return [row.item.offers.is_free == true ? _c('div', [_c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v("Y")])]) : row.item.offers.is_free == false ? _c('div', [_c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v("N")])]) : _vm._e()];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }