<template>
    <div >
      <b-table 
        sticky-header="600px"
        responsive="xl"
        show-empty
        :busy="isLoading"
        hover 
        :isLoading="isLoading"
        :fields="fields"
        :items="discounts">
        <template #head()="row">
          <div class="text-nowrap">
            {{ row.label }}
          </div>
        </template>
        <template #cell(no)="row">
          {{ ( currentPage - 1 ) * perPage + row.index + 1 }}
        </template>
        <template #cell(name)="row">
          <div>
            {{ row.item.offers.name ?  row.item.offers.name : '' }}
          </div>
        </template>
        <template #cell(offer_status)="row">
          <div>
            {{  offerStatus(row.item.offers.offer_status) }}
          </div>
        </template>
        <template #cell(offer_type)="row">
          <div>
            {{  offerType(row.item.offers.offer_type_id) }}
          </div>
        </template>
        <template #cell(price_idr)="row">
          <div>
            {{ row.item.offers.price_idr ?  row.item.offers.price_idr : "Don't have Price" }}
          </div>
        </template>
        <template #cell(is_free)="row">
          <div v-if = "row.item.offers.is_free == true">
            <span class="badge badge-success">Y</span>
          </div>
          <div v-else-if = "row.item.offers.is_free == false">
            <span class="badge badge-danger">N</span>
          </div>
        </template>
        
        <template v-slot:table-busy>
          <div class="text-center text-dark my-2">
            <b-spinner
              class="align-middle"
              variant="dark"
              type="grow"
              label="Loading .."
            >
            </b-spinner>
            Loading ..
          </div>
        </template>
      </b-table>
    </div>
  </template>
  <script>

  import moment from "moment";
    export default {
      name: 'Discounts',
      props: {
        discounts: {
          type: Array,
          required: true
        },
        isLoading: {
          type: Boolean,
          required: true
        },
      },
      data() {
        return {
          currentPage: 1,
          perPage: 50,
          showModal: false,
          datenow : moment().format('YYYY-MM-DD'),
          fields:[
            {
              key:'no',
              label: 'No',
            },
            {
              key: 'name',
              label:'Offers Name '
            },
            {
              key: 'offer_status',
              label:'Offers Status '
            },
            {
              key: 'offer_type',
              label:'Offers Type '
            },
            {
              key: 'price_idr',
              label:'Discount Price IDR'
            },
            {
              key: 'is_free',
              label:'Is Free'
            },
           
          ],
       
        }
      },
      methods: {
        offerStatus(key) {
            if (key == 1) {
                return 'NEW';
            } else if (key == 2) {
                return 'WAITING FOR REVIEW';
            } else if (key == 3) {
                return 'IN REVIEW';
            } else if (key == 4) {
                return 'REJECTED';
            } else if (key == 5) {
                return 'APPROVED';
            } else if (key == 6) {
                return 'PREPARE FOR SALE';
            } else if (key == 7) {
                return 'READY FOR SALE';
            } else if (key == 8) {
                return 'NOT FOR SALE';
            } 
        },
        offerType(key){
            if (key ==1){
                return 'SINGLE'
            }else if (key == 2) {
                return 'SUBSCRIPTIONS';
            } else if (key == 3) {
                return 'BUNDLE';
            } else if (key == 4) {
                return 'BUFFET';
            }
            
        },
    }
  }
  </script>
  <style lang="css" scoped>
  .swal2-input[type=number] {
    max-width: 20em !important;
  }
  .hoverEdit{
    cursor:pointer;
  }
  .hoverEdit:hover {
    color : #2596be
  }
  </style>