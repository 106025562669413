<template>
    <div class="row discount mt-2">
      <b-col lg="12">
        <div class="card" style="min-height: 150vh;overflow-y: auto;">
          <b-row v-if="isError" class="justify-content-center mt-2">
            <b-col lg="10">
              <b-alert variant="danger" show dismissible>
                Opps .. Something is wrong
              </b-alert>
            </b-col>
          </b-row>
          <div class="card-body">
            <b-row class="justify-content-start">
              <div class="col-sm-auto pl-4 pb-2">
                <button type="button" class="btn-sm btn-primary"  @click="$router.push('/discounts')">Back</button>
              </div>
            </b-row>
            <b-row class="justify-content-end">
              <div class="col-sm-auto pb-2">
                <span class="mr-1">
                  <b-button variant="primary" v-b-modal.modal-add-offer>
                    <em class="fa fa-plus"></em> Offers
                  </b-button>
                </span>
                <span>
                  <b-button variant="primary" @click="onReIndexing">re-index</b-button>
                </span>
                Count :
                <strong class="pl-2">
                  {{ formatPriceBasic(totalRows) }}
                </strong>
              </div>
            </b-row>
            <div class="table-responsesive" >
                <TableSpecifiedOffer :discounts="discounts" :isLoading="isLoading" />
              </div>
              <b-row class="justify-content-start ml-1">
                <b-col lg="3">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                  />
                </b-col>
              </b-row>
          </div>     
        </div>   
      </b-col>

      <b-modal id="modal-add-offer" title="Add Offers" :ok-visible="true">
        <b-form id="modal-add-offer" @submit.prevent="onAddOffers">
          <b-form-group>
            <multiselect
                v-model="offers"
                label="long_name"
                track-by="id"
                placeholder="Type to search"
                open-direction="bottom"
                :options="offersOptions"
                :searchable="true"
                :close-on-select="false"
                :loading="isOfferSearch"
                @search-change="offersFind"
                :multiple="true"
                :custom-label="nameWithID"
                @input="onSelectOffers"
              >
              </multiselect>
          </b-form-group>
          <b-form-group>
            <b-form-textarea type="textarea" v-model="offersSelected" @input="onTypeOffer" rows="5"/>
          </b-form-group>
          <b-button variant="primary" size="sm" class="float-right" type="submit">
            save
          </b-button>
        </b-form>
        <template #modal-footer>
          <div class="w-100"></div>
        </template>
      </b-modal>
    </div>
  </template>
  <script>
  import moment from 'moment';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import TableSpecifiedOffer from "../../components/discount/specified-offer.vue";
  export default {
    name: 'discount',
    components:{
        TableSpecifiedOffer
    },
    data() {
      return {
        currentPage: 1,
        perPage: 50,
        filter: {},
        isLoadingSearch: false,
        items_search: [],
        debounce: null,
        offers: [],
        offersSelected: null,
        offersOptions: [],
        isOfferSearch: false,
      };
    },
    watch: {
      currentPage: function() {
        this.actionGetSpecified();
      },
      errorMessage: function(data) {
        if (!data) return;
        this.actionGetSpecified();
        this.$swal({
          icon: 'error',
          toast: "true",
          position: "top-end",
          title: data,
          showConfirmButton: true,
        });
      },
      successMessage: function(data) {
        if (!data) return;
        this.actionGetSpecified();
        this.offers = [];
        this.offersSelected = null;
        this.$swal({
          icon: 'success',
          toast: "true",
          position: "top-end",
          title: data,
          showConfirmButton: true,
        });
      },
    },
    computed: {
      ...mapGetters('discounts', ['discounts']),
      ...mapState({
        isLoading: (state) => state.discounts.isLoading,
        isError: (state) => state.discounts.isError,
        errorMessage: (state) => state.discounts.errorMessage,
        successMessage: (state) => state.discounts.successMessage,
        totalRows: (state) => state.discounts.totalRows,
        discounts: (state) => state.discounts.items,
      }),
    },
    mounted() {
      this.actionGetSpecified();
    },
    methods: {
      ...mapActions('discounts', ['fetchSpecifiedOffers', 'reIndexItemByOffers', 'addOfferDiscSpecified', 'searchOffers']),
      actionGetSpecified() {
        let payload = {
          id: this.$route.params.id,
          page: this.currentPage,
          limit: this.perPage,
        };
        this.fetchSpecifiedOffers(payload);
      },
     
      formatDate(tgl) {
        return moment(tgl).format('DD MMM YY');
      },
      formatDateValid(from, to) {
        return (
          moment(from).format('DD MMM YY') +
          ' - ' +
          moment(to).format('DD MMM YY')
        );
      },
      formatPriceBasic(value) {
        let val = (value / 1).toFixed(0).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      },

      onReIndexing() {
        const offers = this.discounts?.map(val => val.offer_id) ?? []
        this.reIndexItemByOffers({ offers });
      },

      offersFind(query) {
        if (!query) return;

        this.isOfferSearch = true;
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.searchOffers({ query })
            .then((response) => {
              this.offersOptions = response?.data?.data?.rows ?? [];
              this.isOfferSearch = false;
            })
            .catch(() => {
              this.isOfferSearch = false;
            });
        }, 600);
      },
      onSelectOffers() {
        this.offersSelected = this.offers?.map(val => val.id)
          .toString();
      },
      onTypeOffer(data) {
        if (!data) return;
        this.offersSelected = data.replace(/[^0-9,]/g, '');
      },
      onAddOffers(e) {
        e.preventDefault();
        this.addOfferDiscSpecified({
          id: this.$route.params.id,
          offers: this.offersSelected?.split(','),
        });
      },
      nameWithID(data) {
        return `${data.long_name} - [${data.id}]`;
      }
    },
  };
  </script>
  
  <style scoped>
  @import '../../assets/css/table.css';
  
  .card {
    overflow: hidden;
    height: calc(100vh - 140px) !important;
  }
  
  .card.middle > .card-body > div {
    overflow: hidden;
    height: calc(100% - 40px) !important;
  }
  
  .table-responsive-xl {
    overflow-y: auto;
    max-height: calc(100% - 142px) !important;
  }
  
  .discount {
    font-family: 'Avenir Next', 'effra', Helvetica, Arial, sans-serif;
  }
  .tableFixHead {
    overflow-y: auto;
    height: 580px;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
    background-color: #b8daff;
  }
  .content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #f1f1f1;
  }
  </style>
  